.syllabusContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.syllabusContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.syllabusContainer .syllabus-main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.syllabusContainer .syllabus-nav {
    flex: 1;
    background-color: #f0eded;
    padding: 0;
    transition: flex 0.3s;
}

.syllabusContainer .syllabus-nav a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
}

.syllabusContainer .syllabus-nav a:hover,
.syllabusContainer .syllabus-nav a.syllabus-selected {
    background-color: #1976d2;
    color: #fff;
}

.syllabusContainer .syllabus-content {
    flex: 3;
    padding: 20px;
    overflow-y: auto;
}

.syllabusContainer .syllabus-section {
    /* background-color: #f6f6f6; */
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
}

.syllabusContainer .editor ul,
.syllabusContainer .editor ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.syllabusContainer .editor ul {
    list-style-type: disc;
}

.syllabusContainer .editor ol {
    list-style-type: decimal;
}

.syllabusContainer .editor-header,
.syllabusContainer .editor p,
.syllabusContainer .editor li {
    color: black;
    text-align: left;
}

.syllabusContainer .editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.syllabusContainer .remove-section-icon {
    cursor: pointer;
    font-size: 20px;
    color: red;
    margin-left: 15px;
    transition: opacity 0.3s;
}

.syllabusContainer .remove-section-icon:hover {
    opacity: 0.7;
}

.syllabusContainer .add-section-btn {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    align-self: flex-start;
}

.save-syllabus-btn {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 1em;
    transition: background-color 0.3s;
}

.save-syllabus-btn:hover {
    background-color: #0056b3;
}

.syllabusContainer .syllabus-text {
    white-space: pre-line;
}

.syllabusContainer .syllabus-textarea {
    width: 100%;
    height: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    resize: vertical;
}

.banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    width: auto;
    max-width: 80%;
}

.banner-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.banner-failure {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}