/*
    Name: Mannam, Raj Kumar
    Student Id: 1002126524
*/

.courseHomeContainer {
    display: flex;
    flex-direction: column;
}

.courseHomeContainer .courseHome-main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.courseHomeContainer .courseHome-nav {
    flex: 1;
    background-color: #f0eded;
    padding: 0;
    transition: flex 0.3s;
}

.courseHomeContainer .courseHome-nav a {
    display: block;
    padding: 10px 15px;
    background-color: #f0eded;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.courseHomeContainer .courseHome-nav a:hover,
.courseHomeContainer .courseHome-nav a.courseHome-selected {
    background-color: #1976d2;
    color: #fff;
}

.courseHomeContainer .courseHome-content {
    flex: 3;
    padding: 20px;
    overflow-y: auto;
}

.courseHomeContainer .courseHome-section {
    background-color: #f6f6f6;
    /* Adjusted background color */
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.courseHomeContainer .courseHome-section h2,
.courseHomeContainer .courseHome-section p {
    margin: 0;
    padding: 0;
}

.courseHomeContainer .courseHome-section h2 {
    font-size: 24px;
    text-align: left;
    color: #333;
    margin-bottom: 15px;
}

.courseHomeContainer .courseHome-section p {
    line-height: 1.6;
    color: black;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 10px;
}

.description-edit {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    box-sizing: border-box;
}

.save-description-btn,
.edit-description-btn {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.save-description-btn:hover,
.edit-description-btn:hover {
    background-color: #0056b3;
}

.save-description-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    width: auto;
    max-width: 80%;
}

.banner-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.banner-failure {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

@media only screen and (max-width: 768px) {

    .courseHomeContainer .courseHome-nav,
    .courseHomeContainer .courseHome-content {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .courseHomeContainer .courseHome-content {
        padding: 5px;
    }
}