/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/

/* Styles for the main container */
.container-about {
    padding: 40px; /* Add padding inside the container */
    max-width: 1000px; /* Set maximum width to 1000px */
    margin: 0 auto; /* Center the container horizontally */
    background-color: #fff; /* Set background color to white */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    border-radius: 5px; /* Apply a 5px border radius to the container */
}

/* Styles for the main heading */
h1 {
    text-align: center; /* Center align the heading */
    color: #333333; /* Set heading text color to dark gray */
}

/* Styles for paragraph text */
p {
    line-height: 1.5; /* Set line height for paragraphs */
    color: #666666; /* Set paragraph text color to medium gray */
}

/* Margin for section-about elements */
.section-about {
    margin-bottom: 40px; /* Add bottom margin to section-about elements */
}

/* Remove margin for the last paragraph inside a section-about */
.section-about p:last-child {
    margin-bottom: 0; /* Remove margin for the last paragraph in a section-about */
}

/* Styles for the team section-about */
.team {
    display: flex; /* Display team members in a flex layout */
    justify-content: center; /* Center align team members horizontally */
    align-items: center; /* Center align team members vertically */
    flex-wrap: wrap; /* Allow team members to wrap to the next line if needed */
}

/* Styles for individual team members */
.team-member {
    width: 200px; /* Set a fixed width for team member elements */
    margin: 20px; /* Add margin around team member elements */
    padding: 20px; /* Add padding inside team member elements */
    background-color: #ffffff; /* Set background color to white */
    border-radius: 10px; /* Apply a 10px border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    text-align: center; /* Center align text inside team member elements */
}

/* Styles for team member icons */
.team-member i {
    font-size: 48px; /* Set icon font size to 48px */
    color: #333333; /* Set icon color to dark gray */
    margin-bottom: 10px; /* Add margin below the icon */
}

/* Styles for team member names */
.team-member h3 {
    margin-bottom: 5px; /* Add margin below team member names */
    color: #333333; /* Set team member name color to dark gray */
}

/* Styles for team member descriptions */
.team-member p {
    color: #666666; /* Set team member description color to medium gray */
}

/* Styles for secondary headings */
h2 .abouth2 {
    color: #007bff; /* Set secondary heading text color to blue */
}

.content-containerPOS {
    margin: 0 auto;
    background-color: #fff; /*white background color*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /*black shadow color*/
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.table-containerPOS{
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto; /* Center the container horizontally */
}