/*
    Name: Mannam, Raj Kumar
    Student Id: 1002126524
*/

/* General Styles */
.gradesContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.gradesContainer body {
    font-family: Arial, sans-serif;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

/* Main Content Styles */
.gradesContainer .grades-main-content {
    display: flex;
    flex: 1;
}

/* Navigation Styles */
.gradesContainer .grades-nav {
    flex: 1;
    background-color: #f4f4f4;
    padding: 0;
    transition: flex 0.3s;
}

.gradesContainer .grades-nav a {
    display: block;
    padding: 10px 15px;
    background-color: #f0eded;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.gradesContainer .grades-nav a:hover,
.gradesContainer .grades-nav a.grades-selected {
    background-color: #1976d2;
    color: #fff;
}

/* Content Area Styles */
.gradesContainer .grades-content {
    flex: 3;
    padding: 20px;
    color: black;
    /* Ensuring text is black */
}

.gradesContainer .title {

    padding-bottom: 10px;
    margin-bottom: 20px;
    color: black;
    /* Ensuring text is black */
}

/* Exam Selector Styles */
.gradesContainer #examSelect {
    padding: 8px 12px;
    margin: 10px 0;
    font-size: 1rem;
    border: 2px solid #1976d2;
    border-radius: 5px;
    background-color: #ffffff;
    transition: border-color 0.2s;
    width: 200px;
    cursor: pointer;
    outline: none;
}

.gradesContainer #examSelect:hover {
    border-color: #155ba1;
}

.gradesContainer #examSelect:focus {
    border-color: #114785;
}

/* Table Styles */
.gradesContainer #gradesTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.gradesContainer #gradesTable th,
.gradesContainer #gradesTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    color: black;
    /* Ensuring text is black */
}

.gradesContainer #gradesTable td input[type="number"] {
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    border: none;
    /* Remove the default border */
    padding: 8px;
    /* Add some padding for the content */
    box-sizing: border-box;
    /* Make sure padding doesn't affect the total width/height */
    outline: none;
    /* Optional: remove the default focus outline */
}

.gradesContainer #gradesTable td:hover {
    background-color: #f0f0f0;
    /* Light gray background on hover */
    cursor: pointer;
    /* Change the cursor to indicate interactivity */
}

.gradesContainer #gradesTable th {
    background-color: #f2f2f2;
}

/* Feedback Button Styles */
.gradesContainer .feedback-btn {
    padding: 6px 12px;
    background-color: #1976d2;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.2s;
    cursor: pointer;
    outline: none;
}

.gradesContainer .feedback-btn:hover {
    background-color: #155ba1;
}

.gradesContainer .feedback-btn:focus {
    background-color: #114785;
}

/* Feedback Modal Styles */
.feedback-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.feedback-modal-content {
    position: relative;
    /* Ensure this is set to relative */
    background-color: #ffffff;
    padding: 20px;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feedback-modal-content h3 {
    margin-bottom: 15px;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.feedback-modal-content textarea {
    width: 90%;
    height: 150px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    resize: vertical;
}

.feedback-modal-content button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
}

.feedback-modal-content button:first-of-type {
    background-color: #1976d2;
    color: #ffffff;
    transition: background-color 0.2s;
}

.feedback-modal-content button:first-of-type:hover {
    background-color: #155ba1;
}

.feedback-modal-content button:last-of-type {
    background-color: #f2f2f2;
    color: #333;
}

/* Feedback Modal Header */
.feedback-modal-content .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.feedback-modal-content .modal-header h3 {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    border-bottom: none;
    /* Remove the border */
}

/* Close Icon Style */
.close-feedback-modal {
    cursor: pointer;
    font-size: 1.5em;
    background: none;
    border: none;
    padding: 0;
    line-height: 1;
    color: #333;
    margin-left: auto;
    /* This ensures the close icon stays to the right */
}

.banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    width: auto;
    max-width: 80%;
}

.banner-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.banner-failure {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}