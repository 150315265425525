/* General page styles */
.instructorDashboard {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Styles for the primary content section */
.instructorDashboard .main-content {
    padding: 20px;
    text-align: center;
}

/* Heading inside the dashboard which contains primary action buttons */
.instructorDashboard .dashboard-heading {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

/* Primary button styles used in the dashboard */
.instructorDashboard .mbtn {
    padding: 10px 20px;
    border-radius: 8px;
    color: #fff;
    background-color: #1976d2;
    border: none;
    cursor: pointer;
    margin: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.instructorDashboard .mbtn:hover {
    background-color: #1565c0;
}

/* Styles for the courses section on the dashboard */
.instructorDashboard .courses-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.instructorDashboard .course-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    margin: 10px 0;
}

.instructorDashboard .course-widget {
    flex: 0 1 calc(33.333% - 20px);
    /* Adjusting this for 3 per row */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 30px;
    border-radius: 8px;
    background-color: #e6f2ff;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
}


.instructorDashboard .course-widget h3 {
    color: black;
    font-size: 1.17em;
    margin-bottom: 20px;
}

.instructorDashboard .course-widget:hover {
    transform: scale(1.05);
}

.instructorDashboard .course-icon-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: unset
}

.instructorDashboard .course-icon-list li a {
    text-decoration: none;
    font-size: 1.5em;
}

/* Responsive design */
@media (max-width: 1100px) {
    .instructorDashboard .course-widget {
        flex: 0 1 calc(50% - 20px);
    }
}

@media (max-width: 700px) {
    .instructorDashboard .course-widget {
        flex: 0 1 calc(100% - 20px);
    }
}