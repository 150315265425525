/* Name:  Varekar, Shraddha */
/* Student Id:  1002071887 */
/* comments:  I have used the font-family Arial, sans-serif and color #f4f4f4,
    #fff, #e1e1e1, #333, #4c68cc, #007BFF, #000000, in my CSS file. */



.courses-section {
    display: flex;
    /* Flexbox layout for the courses section */
    flex-direction: column;
    /* Stacking the course rows vertically */
    align-items: center;

}

.course-row {
    display: flex;
    /* Flexbox layout for individual course rows */
    justify-content: space-between;
    /* Distributing space between course widgets */
    flex-wrap: wrap;
    /* Allow the widgets to wrap to the next line if there's not enough space */
    width: 100%;
    /* Full width of the parent container */
    max-width: 1200px;
    /* Maximum width for the course row */
    margin: 10px 0;
    /* Vertical spacing between course rows */
}

.course-widget {
    flex: 0 1 calc(30% - 20px);
    /* Flex grow, flex shrink, and basis (taking into account the left and right margin for widgets) */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Light shadow for the course widgets */
    margin: 10px;
    /* Spacing around each widget */
    padding: 20px;
    /* Padding inside each widget */
    border-radius: 8px;
    /* Rounded corners for each widget */
    background-color: #e6f2ff;
    /* Background color of each widget */
    cursor: pointer;
    /* Pointer cursor when hovering over the widget */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Transition for hover effect */
}

.course-widget:hover {
    transform: scale(1.05);
    /* Slightly enlarging the widget on hover */
}

.course-icon-list {
    list-style-type: none;
    /* Removing list markers for the icon list inside each widget */
    padding: 0;
    /* Removing default padding */
    display: flex;
    /* Flexbox layout for the icon list */
    justify-content: center;
    /* Centering the icons horizontally */
    gap: 15px;
    /* Space between each icon */
}

.course-icon-list li a {
    text-decoration: none;
    /* Removing underline for icons (anchor links) */
    font-size: 1.5em;
    /* Enlarging the icons for visibility */
}

.main-content-courses .footer-push {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

/* Responsive styles for different screen sizes */

@media (max-width: 1024px) {
    .course-widget {
        flex: 0 1 calc(48% - 20px);
        /* On smaller screens, two widgets per row */
    }
}

@media (max-width: 999px) {
    .course-widget {
        flex: 0 1 100%;
        /* On mobile screens, one widget per row */
  
    }

    .dashboard-heading {
        flex-direction: column;
        /* Stacking buttons vertically on mobile screens */
    }

    .mbtn {
        margin: 10px auto;
        /* Centering the buttons with equal margin from top and bottom */
        display: block;
        /* Making each button take its own line */
    }
    .main-content-courses .footer-push{
      position: relative;
    }

}