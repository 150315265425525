/* Name: Kolli, Sri Harrsha
  UTAID: 1002063354
/*

/* Features Styles */
.hk .features {
  padding: 80px 20px; /* Top and bottom padding of 80px, left and right padding of 20px */
  text-align: center;
}
.hk .feature {
  border: 0.5px solid black; /* Thin black border for each feature box */
  padding: 20px;
  vertical-align: top;
  display: inline-block;
  width: 200px;
  height: 170px;
  margin: 30px; /* Margin around each feature box */
}
.hk .feature-icon {
  font-size: 48px; /* Font size for feature icons */
  margin-bottom: 20px; /* Bottom margin for feature icons */
}
.hk .feature-title {
  font-size: 24px; /* Font size for feature titles */
  font-weight: bold; /* Bold font weight for feature titles */
  margin-bottom: 10px; /* Bottom margin for feature titles */
}
.hk .feature-description {
  font-size: 16px; /* Font size for feature descriptions */
  color: #666; /* Dark gray color for feature descriptions */
}
