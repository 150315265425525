/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/
.content-containerFeedback h2 {
    color: #075e54; /*green color*/
    text-align: center;
    margin-bottom: 30px;
}

.content-containerFeedback {
    max-width: 1500px;
    margin: 0 auto;
    background-color: #fff; /*white background color*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /*black shadow color*/
    border-radius: 5px;
}

.feedback-form {
    flex: 80%;
    padding: 20px;
}

.feedback-form label {
    color: #075e54; /*green color*/
    font-weight: bold;
}

.feedback-form input[type="text"],
.feedback-form input[type="email"],
.feedback-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc; /*white border color*/
    border-radius: 3px;
    font-size: 14px;
    /* Adjust font size */
}

.feedback-form input[type="submit"] {
    background-color: #075e54; /*green background color*/
    color: #fff; /*white color*/
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.feedback-form input[type="submit"]:hover {
    background-color: #0056b3; /*blue background color*/
}

/* Margin for section-about elements */
.section-feedback {
    margin-bottom: 40px; /* Add bottom margin to section-about elements */
    max-width: 1000px; /* Set maximum width to 1000px */
    margin: 0 auto; /* Center the container horizontally */
}

/* Remove margin for the last paragraph inside a section-about */
.section-feedback p:last-child {
    margin-bottom: 0; /* Remove margin for the last paragraph in a section-about */
}