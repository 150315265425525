/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/


/* Styles for the content container */
.content-containerHome {
    padding: 40px; /* Add padding inside the content container */
    max-width: 1000px; /* Set maximum width to 1000px */
    margin: 0 auto; /* Center the container horizontally */
    background-color: #fff; /* Set background color to white */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    border-radius: 5px; /* Apply a 5px border radius to the container */
}

/* Styles for secondary headings */
.home h2 {
    color: #4c68cc; /* Set secondary heading text color to a blue shade */
}

/* Styles for images */
.homeimg  {
    max-width: 100%; /* Set maximum image width to 100% */
    height: auto; /* Maintain image aspect ratio */
    margin: 20px 0; /* Add margin above and below images */
}

/* Styles for sections */
.sectionHome {
    margin-bottom: 20px; /* Add margin below sections */
}

/* Styles for bullet points */
ul {
    list-style-type: disc; /* Use disc bullets for unordered lists */
    padding-left: 20px; /* Add left padding to lists */
}

/* Styles for program objectives and importance sections */
 .pos {
    width: fit-content; /* Set width to fit content */
    background-color: #007bff; /* Set background color to blue */
    color: white; /* Set text color to white */
}

/* Centered image styles */
.homeimage-container {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

/* Styles for paragraphs and list items */
p, li {
    line-height: 1.5; /* Set line height for paragraphs and list items */
    color: #666666; /* Set text color to medium gray */
}
