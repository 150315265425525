/*
    Name: Mannam, Raj Kumar
    Student Id: 1002126524
*/

/* Ensure styling is scoped to the component */
.instructorProfileContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.instructorProfileContainer {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    text-align: center;
}

.instructorProfileContainer .profile-main-content {
    flex: 1;
    padding: 10px;
}

.instructorProfileContainer .profile-heading {
    color: black;
    font-size: x-large;
}

.instructorProfileContainer .profile-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: left;
}

.instructorProfileContainer .profile-form label,
.instructorProfileContainer .profile-form button {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.instructorProfileContainer .profile-form input[type="text"],
.instructorProfileContainer .profile-form textarea {
    width: calc(100% - 16px);
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.instructorProfileContainer .profile-form button {
    padding: 8px 15px;
    background-color: #1976d2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .instructorProfileContainer .profile-form {
        width: 90%;
    }
}