/*
    Name: Ravi, Gokul
    Student Id: 1002039215
*/
.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 27px;
}

.head {
    margin: 30px 45px;
}

.head h2 {
    font-size: 35px;
}

.icons {
    gap: 20px;
}

.icons .fa-bell {
    font-size: 27px;
}

.profile {
    background-color: rgb(157, 156, 156); /*grey background color*/
    width: 50px;
    height: 50px;
    border-radius: 50%;
 
}

.profile i {
    margin: 0 auto;
    font-size: 27px;
   
}

.containerStudentDashboard {
    display: flex;
    gap: 40px;
    margin: 40px;
}
@media (max-width: 768px) {
    .containerStudentDashboard {
        flex-direction: column; /* For mobile, display content in a row */
    }
    #style-2 {
        flex: 100%; /* Full width for left panel in mobile view */
        max-width: 100%; /* Remove maximum width for left panel */
    }
}
.courseList {
    display: flex;
    flex-wrap: wrap;
    column-gap: 46px;
}

.course {
    width: 100%;
    cursor: pointer;
}

.course.box {
    margin: 10px 0;
}

.attendanceWrapper,
.projectsContainer,
.left,
.center,
.right,
.tablediv {
    padding: 14px;
    border-radius: 20px;
    box-shadow: -3px 4px 26px -6px rgba(92, 92, 92, 1);
    background-color: #4c68cc; /*blue background color*/
}

.attendanceWrapper {
    text-align: center;
}

.attendance {
    font-size: 140px;
    text-align: center;
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%, /*blue background color*/
            #44107a 29%, /*purple background color*/
            #ff1361 67%, /*red background color*/
            #fff800 100%); /*yellow background color*/
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff; /*white color*/
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.attendanceWrapper .label {
    font-size: 18px;
}

.attendanceWrapper a,
.project a,
.course a {
    color: #4c68cc; /*blue background color*/
    font-size: 12px;
    cursor: pointer;
    margin: 10px 0;
}

.projectsContainer {
    width: 50%;
    padding: 25px;
    overflow: scroll;
    height: 290px;
}

.projectContainerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.projectHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
}

.project h5 {
    margin: 5px 0;
}

.project:hover {
    transform: scale(1.06);
    box-shadow: -4px 3px 19px 1px rgba(84, 94, 97, 1); /*grey shadow color*/
}

.course:hover {
    transform: scale(1.1);
    box-shadow: -4px 3px 19px 1px rgba(84, 94, 97, 1); /*grey shadow color*/
}

.classes:hover {
    transform: scale(1.06);
    box-shadow: -4px 3px 19px 1px rgba(84, 94, 97, 1); /*grey shadow color*/
}

.newsBox:hover {
    transform: scale(1.06);
    box-shadow: -4px 3px 19px 1px rgba(84, 94, 97, 1); /*grey shadow color*/
}


.left {
    width: 40%;
    padding: 25px;
    height: 500px;
    overflow: scroll;
}

.center {
    width: 40%;
    padding: 25px;
    height: 500px;
    overflow: scroll;
}

.right {
    width: 40%;
    padding: 25px;
    height: 500px;
    overflow: scroll;
}

.newsTitle {
    text-align: center;
    font-size: 27px;
}

.classesConatiner {
    margin: 35px 0;
}

.classes {
    margin: 30px 0;
}

.classHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.classTitle,
.fa-magnifying-glass,
.fa-calendar-days {
    font-size: 25px;
}

.classHeader {
    font-size: 12px;
}

.classHeader .fa-clock {
    margin: 0 8px;
}

.fa-magnifying-glass,
.fa-calendar-days {
    margin: 0 10px;
}

.box {
    margin: 25px 0px;
    border-radius: 10px;
    padding: 20px;
    background-color: #eef8ff; /*white background color*/
    box-shadow: -4px 3px 17px -6px rgba(107, 118, 122, 1); /*grey shadow color*/
}

.subjectName {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
}

.professor {
    font-weight: 400;
}

.subjectContent {
    font-size: 15px;
    margin: 14px 0;
}

.newsBox {
    margin: 25px 0px;
    border-radius: 10px;
    padding: 20px;
    background-color: #eef8ff; /*white background color*/
    box-shadow: -4px 3px 17px -6px rgba(107, 118, 122, 1); /*grey shadow color*/
}

.newsBox img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.newsBox p {
    font-size: 14px;
    padding: 10px;
}

.schedule {
    width: 30%;
}

.schedule img {
    width: 100%;
    height: 270px;
    object-fit: cover;
}

.tablediv {
    width: 50%;
}

.tablediv h3 {
    margin: 20px 0;
}

/* Apply rounded corners to the table */
table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 10px;
    /* Adjust the value to control the roundness */
    overflow: hidden;
    /* Ensure that the rounded corners are displayed properly */
}

/* Style for table headers (th) */
th {
    text-align: left;
    padding: 8px;
    background-color: #b5bcc1; /*grey background color*/
    color: black; /*black color*/
}

/* Style for table data cells (td) */
td {
    text-align: left;
    padding: 8px;
    background-color: #eef8ff; /*white background color*/
    /* Set the background color for cells */
}

/* Apply alternating row background color */
tr:nth-child(even) {
    background-color: #4c68cc; /*blue background color*/
}

.leaveContainer {
    width: 20%;
}

.leaveBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin: 0;
}

.leaveBox img {
    width: 50px;
}

.leave {
    height: 160px;
    padding: 40px;
}

.leave .label {
    font-size: 20px;
    text-align: center;
    margin: 20px;
}

.leaveDays {
    font-size: 42px;
    text-align: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#style-2::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#style-2 {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Add these styles to your existing CSS or in a new CSS file */
.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff; /*white background color*/
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); /*black shadow color*/
   
}

.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #333; /*grey color*/
}

.dropdown-content a:hover {
    background-color: #f2f2f2; /*white background color*/
}

/* Show the dropdown when the icon is clicked */
.dropdown.show-dropdown .dropdown-content {
    display: block;
}

.dropbtn {
    background-color: #4c68cc; /*blue background color*/
    color: white; /*white color*/
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
    background-color: #5674e4; /*blue background color*/
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1; /*white background color*/
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); /*black shadow color*/
    
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black; /*black color*/
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #5674e4 /*blue background color*/
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
    display: block;
}

.profile {
    position: relative; /* Make the profile container relative */
}

.profile .dropdown-content {
    display: none;
    position: absolute;
    top: 100%; /* Position below the profile */
    left: 0;
    background-color: #f1f1f1; /*white background color*/
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); /*black shadow color*/

    border-radius: 5px; /* Add border radius to the dropdown */
}

.profile:hover .dropdown-content {
    display: block;
}

/* Add border radius to the dropdown fields */
.dropdown-content a {
    color: black; /*black color*/
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 5px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #5674e4; /*blue background color*/
}

h3 {
    color: black;
}

.icon-group {
    display: flex;
    align-items: center; /* Align icons vertically in the middle */
}

.icon-group i {
    margin-right: 10px; /* Add some spacing between the icons */
}
.course-popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 80%;
  }
  
.course-popup-content label {
    font-size: 20px;
    text-align: center;
    margin: 20px;
}
.course-popup-content div{
    margin: 20px;
}

/* Add this to your CSS file */
.course-policy-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears on top of other elements */
  }
  
  .course-policy-popup.open {
    display: flex;
  }
  