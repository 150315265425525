/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/


/* Styles for content container */
.content-containerchat {
    max-width: 1500px; /* Set maximum width for the content container */
    margin: 0 auto; /* Center the container horizontally */
    background-color: #fff; /* Set background color to white */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    border-radius: 5px; /* Apply a 5px border radius */
    display: flex; /* Use flex layout for the content container */
    flex-direction: row; /* Arrange content in a row */
}
@media (max-width: 768px) {
    .content-containerchat {
        flex-direction: column; /* For mobile, display content in a row */
    }
    .left-panelchat {
        flex: 100%; /* Full width for left panel in mobile view */
        max-width: 100%; /* Remove maximum width for left panel */
    }
}
/* Styles for other message containers */
.other-message-container {
    display: flex; /* Use flex layout for other message containers */
    justify-content: flex-start; /* Align messages to the left */
    margin-right: 30%; /* Add margin to the right */
    margin-bottom: 10px; /* Add margin at the bottom */
}

/* Styles for my message containers */
.my-message-container {
    display: flex; /* Use flex layout for my message containers */
    justify-content: flex-end; /* Align messages to the right */
    margin-bottom: 10px; /* Add margin at the bottom */
    margin-left: 30%; /* Add margin to the left */
}

/* Styles for message boxes */
.message {
    padding: 10px; /* Add padding to message boxes */
    border-radius: 10px; /* Apply a 10px border radius */
}

/* Styles for my messages */
.my-message {
    background-color: #dcf8c6; /* Set background color for my messages */
    align-self: flex-end; /* Align my messages to the right */
}

/* Styles for other messages */
.other-message {
    background-color: #fff; /* Set background color for other messages */
    border: 1px solid #ccc; /* Add a 1px solid border */
    align-self: flex-start; /* Align other messages to the left */
}

/* Styles for message senders */
.sender {
    font-weight: bold; /* Set font weight to bold for senders */
    color: #075e54; /* Set sender text color to green */
}

/* Styles for input container */
.input-container {
    margin-top: 20px; /* Add margin at the top */
    margin-right: 20px; /* Add margin at the right */
}

/* Styles for textareas */
textarea {
    width: 100%; /* Set textarea width to 100% */
    height: 80px; /* Set textarea height */
    padding: 10px; /* Add padding to textareas */
    border: 1px solid #ccc; /* Add a 1px solid border */
    border-radius: 5px; /* Apply a 5px border radius */
    resize: none; /* Disable textarea resizing */
}

/* Styles for buttons */
button {
    padding: 10px 20px; /* Add padding to buttons */
    background-color: #075e54; /* Set button background color to green */
    color: #fff; /* Set button text color to white */
    border: none; /* Remove button border */
    border-radius: 5px; /* Apply a 5px border radius */
    cursor: pointer; /* Change cursor to pointer on hover */
    margin-top: 10px; /* Add margin at the top */
}

/* Styles for left panel */
.bar {
    width: 25px; /* Set bar width */
    height: 3px; /* Set bar height */
    background-color: #fff; /* Set bar background color to white */
    margin: 3px 0; /* Add margin to bars */
}

.menu-icon {
    display: flex; /* Use flex layout for menu icons */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center-align items horizontally */
    width: 40px; /* Set menu icon width */
    height: 40px; /* Set menu icon height */
    cursor: pointer; /* Change cursor to pointer on hover */
}

.left-panelchat {
    flex: 20%; /* Set the left panel to 20% of the container */
    background-color: #f2f2f2; /* Set left panel background color to light gray */
    transition: width 0.3s ease; /* Add transition for width change */
    padding: 20px; /* Add padding inside the left panel */
    border-radius: 10px; /* Apply a 10px border radius */
}

.pinned .menu-icon {
    display: none; /* Hide menu icons when pinned */
}

.pinned .left-panel {
    width: 250px; /* Set width of the left panel when pinned */
}

/* Styles for sections */
.section {
    margin-bottom: 20px; /* Add margin at the bottom of sections */
}

/* Styles for section titles */
.section-title {
    font-size: 18px; /* Set font size for section titles */
    font-weight: bold; /* Set font weight to bold */
    margin-bottom: 10px; /* Add margin at the bottom of section titles */
}

/* Styles for section content */
.section-content {
    background-color: #ffffff; /* Set background color for section content */
    padding: 10px; /* Add padding to section content */
    border-radius: 5px; /* Apply a 5px border radius */
}



/* Styles for user list */
.user-list {
    list-style-type: none; /* Remove list bullets */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
}

/* Styles for user list items */
.user-list-item {
    display: flex; /* Use flex layout for user list items */
    align-items: center; /* Align items vertically */
    padding: 10px; /* Add padding to user list items */
    background-color: #fff; /* Set background color to white */
    border: 1px solid #ccc; /* Add a 1px solid border */
    border-radius: 5px; /* Apply a 5px border radius */
    margin-bottom: 10px; /* Add margin at the bottom of list items */
    cursor: pointer; /* Change cursor to pointer on hover */
}

/* Styles for user list items on hover */
.user-list-item:hover {
    background-color: #cfd2d1; /* Change background color on hover */
}

/* Styles for user names */
.user-name {
    font-weight: bold; /* Set font weight to bold for user names */
    margin: 0; /* Remove default margin */
}

/* Styles for user statuses */
.user-status {
    font-size: 12px; /* Set font size for user statuses */
    color: #888; /* Set text color for user statuses */
}

/* Styles for the right container */
.right-containerchat {
    flex: 80%; /* Set the right container to 80% of the available space */
    background-color: #fff; /* Set background color to white */
    display: flex; /* Use flex layout for the right container */
    flex-direction: column; /* Arrange items vertically */
}

/* Styles for the right panel */
.right-panelchat {
    flex: 80%; /* Set the right panel to 80% of the available space */
    background-color: #fff; /* Set background color to white */
    padding: 20px; /* Add padding inside the right panel */
    border-radius: 10px; /* Apply a 10px border radius */
}


/* Styles for content header */
.content-header {
    min-width: 500px; /* Set minimum width for the content header */
    margin: 0 auto; /* Center the content header horizontally */
    background-color: #fff; /* Set background color to white */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    border-radius: 5px; /* Apply a 5px border radius */
    display: flex; /* Use flex layout for the content header */
    flex-direction: row; /* Arrange items horizontally */
}

/* Styles for search input */
.search-input {
    width: 92%; /* Set input width to 92% */
    padding: 10px; /* Add padding to the input */
    border: 1px solid #ccc; /* Add a 1px solid border */
    border-radius: 5px; /* Apply a 5px border radius */
    font-size: 14px; /* Set font size for the input */
    margin-bottom: 10px; /* Add margin at the bottom */
}