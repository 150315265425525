/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/

/* Styles for post containers */
.post-container {
    width: 100%; /* Set the width to 100% */
    margin-bottom: 10px; /* Add margin at the bottom of post containers */
}

/* Styles for message elements */
.message {
    padding: 10px; /* Add padding inside message elements */
    border-radius: 10px; /* Apply a 10px border radius */
}

/* Styles for my messages */
.my-message {
    background-color: #dcf8c6; /* Set background color for my messages */
    align-self: flex-end; /* Align my messages to the right */
}

/* Styles for other messages */
.other-message {
    background-color: #fff; /* Set background color for other messages */
    border: 1px solid #ccc; /* Add a 1px solid border */
    align-self: flex-start; /* Align other messages to the left */
}

/* Styles for author names */
.author {
    font-weight: bold; /* Set font weight to bold for author names */
    color: #075e54; /* Set text color for author names */
}

/* Styles for sender names */
.sender {
    font-weight: bold; /* Set font weight to bold for sender names */
    color: #075e54; /* Set text color for sender names */
}

/* Styles for input containers */
.input-container {
    margin-top: 20px; /* Add margin at the top of input containers */
    margin-right: 20px; /* Add margin at the right of input containers */
}

/* Styles for textareas */
textarea {
    width: 100%; /* Set textarea width to 100% */
    height: 80px; /* Set textarea height */
    padding: 10px; /* Add padding inside textareas */
    border: 1px solid #ccc; /* Add a 1px solid border */
    border-radius: 5px; /* Apply a 5px border radius */
    resize: none; /* Disable textarea resizing */
}

/* Styles for buttons */
button {
    padding: 10px 20px; /* Add padding to buttons */
    background-color: #075e54; /* Set background color for buttons */
    color: #fff; /* Set text color for buttons */
    border: none; /* Remove button borders */
    border-radius: 5px; /* Apply a 5px border radius */
    cursor: pointer; /* Change cursor to pointer on hover */
    margin-top: 10px; /* Add margin at the top of buttons */
}

/* Styles for the left panel */
.bar {
    width: 25px; /* Set the width of the bar */
    height: 3px; /* Set the height of the bar */
    background-color: #fff; /* Set the background color of the bar */
    margin: 3px 0; /* Add margin to the bar */
}

/* Styles for menu icons */
.menu-icon {
    display: flex; /* Use flex layout for menu icons */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center-align items horizontally */
    width: 40px; /* Set the width of menu icons */
    height: 40px; /* Set the height of menu icons */
    cursor: pointer; /* Change cursor to pointer on hover */
}

/* Styles for pinned menu icons */
.pinned .menu-icon {
    display: none; /* Hide menu icons when pinned */
}

/* Styles for the left panel when pinned */
.pinned .left-panel {
    width: 250px; /* Set width of the left panel when pinned */
}

/* Styles for sections */
.section {
    margin-bottom: 20px; /* Add margin at the bottom of sections */
}

/* Styles for section titles */
.section-title {
    font-size: 18px; /* Set font size for section titles */
    font-weight: bold; /* Set font weight to bold for section titles */
    margin-bottom: 10px; /* Add margin at the bottom of section titles */
    align-items: center; /* Align items vertically */
}

/* Styles for section content */
.section-content {
    background-color: #ffffff; /* Set background color for section content */
    padding: 10px; /* Add padding inside section content */
    border-radius: 5px; /* Apply a 5px border radius */
}


/* Styles for forum lists */
.forum-list {
    list-style-type: none; /* Remove list bullets */
    padding: 0; /* Remove default padding */
    margin-top: 0%;
    margin-bottom: 0%;
    margin-left: 0%;
    margin-right: 0%;
}


/* Styles for forum list items */
.forum-list-item {
    display: flex; /* Use flex layout for forum list items */
    align-items: center; /* Align items vertically */
    padding: 10px; /* Add padding to forum list items */
    background-color: #ffffff; /* Set background color for forum list items */
    border: 1px solid #ccc; /* Add a 1px solid border */
    border-radius: 5px; /* Apply a 5px border radius */
    margin-bottom: 10px; /* Add margin at the bottom of forum list items */
    cursor: pointer; /* Change cursor to pointer on hover */
}

/* Styles for hovered forum list items */
.forum-list-item:hover {
    background-color: #cfd2d1; /* Change background color on hover */
}

/* Styles for forum names */
.forum-name {
    font-weight: bold; /* Set font weight to bold for forum names */
    margin: 0; /* Reset margin for forum names */
}


.left-panel p, .left-panel ul {
    list-style-type: none; /* Remove list bullets */
    padding: 0; /* Remove default padding */
    margin: 0%;
}