/* Name:  Varekar, Shraddha */
/* Student Id:  1002071887 */
/* comments:  I have used the font-family Arial, sans-serif and color #f4f4f4,
    #fff, #e1e1e1, #333, #4c68cc, #007BFF, #000000,
in my CSS file. */


.main-content .main {
    font-family: Arial, sans-serif; /* Setting the font family to Arial, sans-serif */

    margin: 0; /* Removing the margin */
    display: flex; /* Displaying the main as flexbox */
    justify-content: center; /* Center aligning the flex items horizontally */
    align-items: center; /* Center aligning the flex items vertically */
    background-color: #f4f4f4; /* Setting the background color to light grey */
    
}


.main-content .error {
    color: red;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 0.9rem;
}


.main-content .container_shrd {
    width: 100%; /* Setting the width to 100% */
    max-width: 400px; /* Setting the max width to 400px */
    background: #fff; /* Setting the background color to white */
    padding: 3px; /* Adding padding 50px */
    background-color: #f4f4f4; /* Setting the background color to light grey */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Adding box shadow */
        /* center align horizontal and vertical */
    margin: 100px auto;
}

.main-content .container-reg {
    width: 100%; /* Setting the width to 100% */
    max-width: 400px; /* Setting the max width to 400px */
    background: #fff; /* Setting the background color to white */
    padding: 3px; /* Adding padding 50px */
    background-color: #f4f4f4; /* Setting the background color to light grey */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Adding box shadow */
        /* center align horizontal and vertical */
    margin: 10px auto;
}


.main-content .form input[type="text"], .form input[type="password"], .form input[type="date"] {
    display: block; /* Displaying the input as block */
    padding: 10px; /* Adding padding 10px */
    margin-bottom: 10px; /* Adding margin bottom 10px */
    border: 1px solid #e1e1e1; /* Adding border 1px solid #e1e1e1 */
    margin-left: auto; /* Setting the margin left to auto */
    margin-right: auto; /* Setting the margin right to auto */
    width: 300px; /* Setting the width to 300px */
}

.main-content .form button {
    width: 100%; /* Setting the width to 100% */
    padding: 10px; /* Adding padding 10px */
    background-color:  #4c68cc; /* Setting the background color to blue */
    color: #fff; /* Setting the color to white */
    border: none; /* Removing the border */
    cursor: pointer; /* Setting the cursor to pointer */
}

.main-content .radio-group {
    /* Styling for the whole radio group container */
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    max-width: 250px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: #000000;
}

.main-content .radio-option {
    /* Styling for each radio button option */
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.main-content .radio-option input[type="radio"] {
    /* Styling for the radio button */
    margin-right: 8px;
}

.main-content .radio-option label {
    /* Styling for the label of each radio button */
    cursor: pointer;
    color: #000000;
}

/* Responsive design */
@media (max-width: 480px) { 
    .main-content .container_shrd {
        padding: 3px; /* Adding padding 10px */
        margin-left: auto; /* Setting the margin left to auto */
        margin-right: auto; /* Setting the margin right to auto */
    }

    .main-content .form input[type="text"], .form input[type="password"] {
        display: block; /* Displaying the input as block */
        padding: 10px; /* Adding padding 10px */
        margin-bottom: 10px; /* Adding margin bottom 10px */
        border: 1px solid #e1e1e1; /* Adding border 1px solid #e1e1e1 */
        margin-left: auto; /* Setting the margin left to auto */
        margin-right: auto; /* Setting the margin right to auto */
        width: auto; /* Setting the width to 300px */
    }

    .main-content #forgot-password-form input[type="text"] {
        padding: 10px;  /* Adding padding 10px */
        margin-bottom: 10px; /* Adding margin bottom 10px */
        border: 1px solid #e1e1e1; /* Adding border 1px solid #e1e1e1 */
        margin-left: auto; /* Setting the margin left to auto */
        margin-right: auto; /* Setting the margin right to auto */
        display: block; /* Displaying the input as block */
        width: auto; /* Setting the width to 300px */
    }

}


.main-content .forgot-password {
    margin-top: 10px; /* Adding margin top 10px */
    display: block; /* Displaying the link as block */
    background: none;   
    border: none;
    cursor: pointer;    /* Setting the cursor to pointer */
    color: #007BFF; /* Setting the color to blue */
    text-align: center; /* Center aligning the text */
    font-size: 12px; /* Setting the font size to 12px */
    text-decoration: underline; /* Underlining the text */
    padding: 5px 0; /* Adding padding 5px 0 */
} 

.main-content .forgot-password:hover {
    color: #000000; /* Changing the color on hover */
}


.main-content .c_h2 {
    text-align: center;         /* Center aligning the text */
    margin-bottom: 20px;       /* Adding margin bottom 20px */
    color:#000000
}

.main-content p {
    text-align: center;        /* Center aligning the text */
    margin-bottom: 20px;      /* Adding margin bottom 20px */
}

.main-content #forgot-password-form {
    margin-bottom: 20px;     /* Adding margin bottom 20px */
}

.main-content #forgot-password-form input[type="email"] {
    padding: 10px;  /* Adding padding 10px */
    margin-bottom: 10px; /* Adding margin bottom 10px */
    border: 1px solid #e1e1e1; /* Adding border 1px solid #e1e1e1 */
    margin-left: auto; /* Setting the margin left to auto */
    margin-right: auto; /* Setting the margin right to auto */
    width: 300px; /* Setting the width to 300px */
    display: block; /* Displaying the input as block */
}

.main-content #forgot-password-form button {
    width: 200px; /* Setting the width to 200px */
    padding: 10px; /* Adding padding 10px */
    background-color: #4c68cc; /* Setting the background color to blue */
    color: #fff; /* Setting the color to white */
    border: none; /* Removing the border */
    cursor: pointer; /* Setting the cursor to pointer */

    /* Center alignment */
    display: block; 
    margin-left: auto;
    margin-right: auto;
}


.main-content .back-to-login {
    display: block; /* Displaying the link as block */
    text-align: center; /* Center aligning the text */
    color: #FFF; /* Setting the color to white */
    text-decoration: none; /* Removing the underline */
}

.main-content .back-to-login:hover {
    text-decoration: underline; /* Underlining the text on hover */
}



.main-content .backtosignin {
    width: 200px;  /* Setting the width to 200px */
    padding: 10px; /* Adding padding 10px */
    background-color: #4c68cc; /* Setting the background color to blue */
    color: #fff; /* Setting the color to white */
    border: none; /* Removing the border */
    cursor: pointer; /* Setting the cursor to pointer */

    /* Center alignment */
    display: block;
    margin-left: auto;
    margin-right: auto;
}



.main-content .footer-push {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
}