/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/

/* Styles for the policy table */
#policy-table {
  width: 100%; /* Set table width to 100% */
  border-collapse: collapse; /* Collapse table borders */
}

#policy-table th,
#policy-table td {
  border: 1px solid black; /* Add 1px solid border to table cells */
  padding: 8px; /* Add padding to table cells */
  text-align: left; /* Align text to the left in table cells */
}

/* Styles for delete button */
.delete-button {
  background-color: red; /* Set background color to red */
  color: white; /* Set text color to white */
  border: none; /* Remove button border */
  cursor: pointer; /* Change cursor to pointer */
  align-items: center; /* Align button content vertically */
}

/* Styles for update button */
.update-button {
  background-color: #007bff; /* Set background color to blue */
  color: white; /* Set text color to white */
  border: none; /* Remove button border */
  cursor: pointer; /* Change cursor to pointer */
  align-items: center; /* Align button content vertically */
}

/* Styles for add button */
.add-button {
  margin-top: 10px; /* Add top margin */
  padding: 5px 10px; /* Add padding to button */
  cursor: pointer; /* Change cursor to pointer */
}

/* Styles for form container */
.form-container {
  margin-bottom: 20px; /* Add bottom margin to form container */
}

.form-container input[type="text"],
.form-container textarea {
  width: 50%; /* Set input and textarea width to 50% */
  padding: 10px; /* Add padding to input and textarea */
  border-radius: 5px; /* Apply 5px border radius */
  border: 1px solid #dddddd; /* Add 1px solid border */
}

.form-container button {
  padding: 10px 20px; /* Add padding to button */
  background-color: #007bff; /* Set background color to blue */
  border: none; /* Remove button border */
  color: #ffffff; /* Set text color to white */
  font-weight: bold; /* Set font weight to bold */
  border-radius: 5px; /* Apply 5px border radius */
  cursor: pointer; /* Change cursor to pointer */
}

.form-container button:hover {
  background-color: #0056b3; /* Change background color on hover */
}

/* Styles for h1 element */
h1 {
  color: #007bff; /* Set text color to blue */
  text-align: center; /* Center-align the text */
  margin-bottom: 30px; /* Add bottom margin */
}

/* Styles for h2 element */
.section h2 {
  padding-left: 20px; /* Add left padding */
  color: #4c68cc; /* Set text color to a shade of blue */
  margin-bottom: 30px; /* Add bottom margin */
}

/* Styles for unordered lists */
ul {
  list-style-type: disc; /* Use disc bullets for unordered lists */
  margin-left: 20px; /* Add left margin */
}

/* Styles for the left menu */
#leftMenu {
  width: 20%; /* Set the width of the left menu */
  height: 100%; /* Set the height of the left menu */
  background-color: #f2f2f2; /* Set background color for the left menu */
  float: left; /* Float the left menu to the left */
}

/* Styles for menu buttons */
.menuButton {
  display: block; /* Display menu buttons as blocks */
  width: 90%; /* Set button width to 90% */
  padding: 10px; /* Add padding to menu buttons */
  margin-bottom: 20px; /* Add bottom margin to menu buttons */
  background-color: #4c68cc; /* Set background color for menu buttons */
  color: white; /* Set text color to white */
  text-align: center; /* Center-align the text */
  text-decoration: none; /* Remove text decoration */
}


/* Styles for the content header */
.content-header {
  min-width: 600px; /* Set minimum width for the content header */
  margin: 0 auto; /* Center the content header horizontally */
  background-color: #fff; /* Set background color for the content header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  border-radius: 5px; /* Apply 5px border radius */
  display: flex; /* Use flex layout for the content header */
  flex-direction: row; /* Arrange items horizontally in content header */
}

/* Styles for the right container */
.right-container {
  flex: 80%; /* Set the flex property to 80% for the right container */
  background-color: #fff; /* Set background color for the right container */
  display: flex; /* Use flex layout for the right container */
  flex-direction: column; /* Arrange items vertically in the right container */
}

/* Styles for the left panel */
.left-panelQA {
  flex: 20%; /* Set the flex property to 20% for the left panel */
  max-width: 300px; /* Set maximum width for the left panel */
  background-color: #f2f2f2; /* Set background color for the left panel */
  transition: width 0.3s ease; /* Add transition effect for width changes */
  padding: 20px; /* Add padding to the left panel */
  padding-top: 50px; /* Add top padding to the left panel */
  border-radius: 10px; /* Apply 10px border radius to the left panel */
}

/* Styles for h3 elements */
h3 {
  padding-left: 10px; /* Add left padding to h3 elements */
  color: #4c68cc; /* Set text color to a shade of blue */
  margin-top: 0; /* Remove top margin from h3 elements */
}



/* Styles for paragraphs and unordered lists */
p, ul {
  margin-left: 40px; /* Add left margin to paragraphs and lists */
  margin-top: 0; /* Remove top margin from paragraphs and lists */
}

/* Styles for links */
.link {
  padding-left: 20px; /* Add left padding to links */
}

/* Styles for list items */
li {
  margin-left: 20px; /* Add left margin to list items */
  margin-bottom: 10px; /* Add bottom margin to list items */
}

/* Styles for sections */
.section {
  margin-bottom: 40px; /* Add bottom margin to sections */
}

/* Styles for paragraphs */
p {
  line-height: 1.5; /* Set line height for paragraphs */
  color: #666666; /* Set text color for paragraphs */
}

/* Styles for reports container */
.reports {
  margin-top: 20px; /* Add top margin to reports container */
  padding: 20px; /* Add padding to reports container */
  background-color: #ffffff; /* Set background color for reports container */
  border-radius: 5px; /* Apply 5px border radius to reports container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

/* Styles for report headings */
.reports h3 {
  margin-bottom: 10px; /* Add bottom margin to report headings */
  color: #333333; /* Set text color for report headings */
}

/* Styles for report paragraphs */
.reports p {
  color: #666666; /* Set text color for report paragraphs */
}

/* Styles for report tables */
.reports table {
  width: 100%; /* Set table width to 100% */
  border-collapse: collapse; /* Collapse table borders */
  border: 2px solid #333333; /* Add 2px solid border to table */
}

/* Styles for table headers and data cells */
.reports th,
.reports td {
  padding: 8px; /* Add padding to table headers and data cells */
  text-align: left; /* Align text to the left in headers and data cells */
  border-bottom: 1px solid #dddddd; /* Add 1px solid bottom border */
}

/* Styles for table headers */
.reports th {
  background-color: #f5f5f5; /* Set background color for table headers */
  color: #333333; /* Set text color for table headers */
  font-weight: bold; /* Set font weight to bold for table headers */
  border-right: 2px solid #dddddd; /* Add 2px solid right border to table headers */
}

/* Styles for even rows in the table */
.reports tr:nth-child(even) {
  background-color: #f9f9f9; /* Set background color for even rows */
}

/* Styles for the graph container */
.graph {
  margin-top: 20px; /* Add top margin to the graph container */
  padding-left: 20px; /* Add left padding to the graph container */
}

/* Styles for analytics buttons in the graph container */
.graph .analytics-button {
  margin-top: 10px; /* Add top margin to analytics buttons */
  padding: 10px 20px; /* Add padding to analytics buttons */
  background-color: #007bff; /* Set background color for analytics buttons */
  border: none; /* Remove button border */
  color: #ffffff; /* Set text color to white */
  font-weight: bold; /* Set font weight to bold */
  border-radius: 5px; /* Apply 5px border radius to analytics buttons */
  text-decoration: none; /* Remove text decoration */
  display: inline-block; /* Display buttons as inline blocks */
  transition: background-color 0.3s ease; /* Add transition effect for background color */
}

/* Styles for analytics buttons on hover */
.graph .analytics-button:hover {
  background-color: #0056b3; /* Change background color on hover */
}

/* Add this to your CSS file */
.update-policy-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it appears on top of other elements */
}

.update-popup-content {
  background-color: #fff; /* White background for the popup */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 80%;
  z-index: 1001; /* Ensure the content appears above the overlay */
}

/* Add more styles as needed to style the content inside the popup */
