/* Name:  Varekar, Shraddha */
/* Student Id:  1002071887 */
/* comments:  I have used the font-family Arial, sans-serif and color #f4f4f4, 
    #fff, #e1e1e1, #333, #4c68cc, #007BFF, #000000, in my CSS file. */


.permissions-page {
    font-family: Arial, sans-serif; /* Setting the font family to Arial, sans-serif */
    max-width: 600px; /* Setting the max width to 600px */
    margin: 50px auto; /* Setting the margin to 50px auto */
    padding: 40px; /* Adding padding 40px */
    background-color: #fff; /* Setting the background color to white */
    border-radius: 10px; /* Adding border radius 10px */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Adding box shadow */
}

.students-pageManage h1, h2 {
    text-align: center; /* Center aligning the text */
    color: #343a40; /* Setting the color to dark grey */
}

#permissionsForm {
    margin-top: 20px; /* Adding margin top 20px */
}

.permissionsForm label {
    display: block; /* Displaying the label as block */
    margin: 10px 0; /* Adding margin 10px 0 */
}

button { 
    display: block; /* Displaying the button as block */
    background-color: #007BFF; /* Setting the background color to blue */
    color: #fff; /* Setting the color to white */
    border: none;   
    padding: 10px 20px; /* Adding padding 10px 20px */
    margin: 20px auto; /* Adding margin 20px auto */
    border-radius: 5px; /* Adding border radius 5px */
    cursor: pointer; /* Setting the cursor to pointer */
    transition: background-color 0.3s; /* Adding transition */
}

button:hover {
    background-color: #0056b3; /* Changing the background color on hover */
}


.permissions-page form {
    /* Add styling for form */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.permissions-page label {
    /* Add styling for labels */
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
}

.permissions-page input[type="checkbox"] {
    /* Add styling for checkboxes */
    margin: 0 8px 0 0;
    cursor: pointer;
}
