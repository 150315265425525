/*
    Name: Ravi, Gokul
    Student Id: 1002039215
*/
.content-containerStudent h2 {
    color: #075e54;
    text-align: center;
    margin-bottom: 30px;
}

.content-containerStudent {
    max-width: 1500px;
    margin: 0 auto;
    background-color: #fff; /*white background color*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /*black shadow color*/
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

.profile-form {
    flex: 80%;
    padding: 20px;
}

.profile-form label {
    color: #075e54; /*green color*/
    font-weight: bold;
}

.profile-form input[type="text"],
.profile-form input[type="email"],
.profile-form input[type="password"],
.profile-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc; /*white border color*/
    border-radius: 3px;
    font-size: 14px; /* Adjust font size */
}

.profile-form input[type="submit"] {
    background-color: #075e54; /*green background color*/
    color: #fff; /*white color*/
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.profile-form input[type="submit"]:hover {
    background-color: #0056b3; /*blue background color*/
}

.profile-form .error {
    color: red;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 0.9rem;
}
