/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/

/* Styles for h1 elements */
h1 {
  color: #007bff; /* Set text color to a shade of blue */
  text-align: center; /* Center-align h1 elements */
  margin-bottom: 30px; /* Add bottom margin to h1 elements */
}

/* Styles for h2 elements */
.section h2 {
  padding-left: 20px; /* Add left padding to h2 elements */
  color: #4c68cc; /* Set text color to a shade of blue */
  margin-bottom: 30px; /* Add bottom margin to h2 elements */
}

/* Styles for unordered lists */
ul {
  list-style-type: disc; /* Set list style type to disc (bullets) */
  margin-left: 20px; /* Add left margin to unordered lists */
}


/* Styles for content headers */
.content-header {
  min-width: 500px; /* Set minimum width for content headers */
  margin: 0 auto; /* Center content headers horizontally */
  background-color: #fff; /* Set background color for content headers */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  border-radius: 5px; /* Apply 5px border radius */
  display: flex; /* Use flex layout for content headers */
  flex-direction: row; /* Arrange items horizontally in content headers */
}

/* Styles for content sections */
.content {
  padding: 20px; /* Add padding to content sections */
}

/* Styles for the left menu */
#leftMenu {
  width: 200px; /* Set the width of the left menu */
  height: 100%; /* Set the height of the left menu */
  background-color: #f2f2f2; /* Set background color for the left menu */
  float: left; /* Float the left menu to the left */
}

/* Styles for menu buttons */
.menuButton {
  display: block; /* Display menu buttons as block elements */
  width: 90%; /* Set the width of menu buttons */
  padding: 10px; /* Add padding to menu buttons */
  margin-bottom: 20px; /* Add bottom margin to menu buttons */
  background-color: #4c68cc; /* Set background color for menu buttons */
  color: white; /* Set text color to white */
  text-align: center; /* Center-align menu buttons */
  text-decoration: none; /* Remove text decoration */
}



/* Styles for h3 elements in the left panel */
.left-panel h3 {
  padding-left: 10px; /* Add left padding to h3 elements */
  color: #4c68cc; /* Set text color to a shade of blue */
  margin-top: 0; /* Remove top margin from h3 elements */
}

/* Styles for h4 elements in the left panel */
.left-panel h4 {
  padding-left: 40px; /* Add left padding to h4 elements */
  margin-top: 0; /* Remove top margin from h4 elements */
}

/* Styles for paragraphs and unordered lists in the left panel */
.left-panel p, .left-panel ul {
  margin-left: 40px; /* Add left margin to paragraphs and lists */
  margin-top: 0; /* Remove top margin from paragraphs and lists */
}

/* Styles for links in the left panel */
.left-panel .link {
  padding-left: 20px; /* Add left padding to links */
}

/* Styles for list items in the left panel */
.left-panel li {
  margin-left: 20px; /* Add left margin to list items */
  margin-bottom: 10px; /* Add bottom margin to list items */
}

/* Styles for sections in the left panel */
.left-panel .section {
  margin-bottom: 20px; /* Add bottom margin to sections */
}

/* Styles for paragraphs */
p {
  line-height: 1.5; /* Set line height for paragraphs */
  color: #666666; /* Set text color for paragraphs */
}

/* Styles for reports container */
.reports {
  margin-top: 20px; /* Add top margin to reports container */
  padding: 20px; /* Add padding to reports container */
  background-color: #ffffff; /* Set background color for reports container */
  border-radius: 5px; /* Apply 5px border radius to reports container */
}

/* Styles for report headings */
.reports h3 {
  margin-bottom: 10px; /* Add bottom margin to report headings */
  color: #333333; /* Set text color for report headings */
}

/* Styles for report paragraphs */
.reports p {
  color: #666666; /* Set text color for report paragraphs */
}

/* Styles for report tables */
.reports table {
  width: 100%; /* Set table width to 100% */
  border-collapse: collapse; /* Collapse table borders */
  border: 2px solid #dddddd; /* Add 2px solid border to tables */
}

/* Styles for table headers and data cells in reports */
.reports th,
.reports td {
    padding: 8px; /* Add padding to table headers and data cells */
    text-align: left; /* Align text to the left within cells */
    border-bottom: 1px solid #dddddd; /* Add a bottom border to cells */
}

/* Styles for table headers in reports */
.reports th {
    background-color: #f5f5f5; /* Set background color for table headers */
    color: #333333; /* Set text color for table headers */
    font-weight: bold; /* Apply bold font weight to table headers */
    border-right: 2px solid #dddddd; /* Add a right border to table headers */
}

/* Styles for even rows in reports */
.reports tr:nth-child(even) {
    background-color: #f9f9f9; /* Set background color for even rows */
}

/* Styles for the graph container */
.graph {
    padding-left: 20px; /* Add left padding to the graph container */
}

/* Styles for analytics buttons in the graph container */
.graph .analytics-button {
    margin-top: 10px; /* Add top margin to analytics buttons */
    padding: 10px 20px; /* Add padding to analytics buttons */
    background-color: #007bff; /* Set background color for analytics buttons */
    border: none; /* Remove borders from analytics buttons */
    color: #ffffff; /* Set text color for analytics buttons */
    font-weight: bold; /* Apply bold font weight to analytics buttons */
    border-radius: 5px; /* Apply 5px border radius to analytics buttons */
    text-decoration: none; /* Remove text decoration from analytics buttons */
    display: inline-block; /* Display analytics buttons as inline blocks */
    transition: background-color 0.3s ease; /* Add background color transition */
}

/* Hover styles for analytics buttons in the graph container */
.graph .analytics-button:hover {
    background-color: #0056b3; /* Change background color on hover */
}

/* Styles for the container */
.container {
    max-width: 00px; /* Set maximum width for the container */
    margin: 20px auto; /* Center the container horizontally with margin */
    padding: 20px; /* Add padding to the container */
}

/* Styles for h2 elements */
.section h2 {
    margin-top: 20px; /* Add top margin to h2 elements */
}

/* Styles for tables */
.tableQA table {
    width: 100%; /* Set table width to 100% */
    border-collapse: collapse; /* Collapse table borders */
    border: 1px #333333;
}

/* Styles for table headers and data cells */
.tableQA th, td {
    border: 1px solid #ddd; /* Add 1px solid border to table cells */
    padding: 8px; /* Add padding to table cells */
    text-align: left; /* Align text to the left within cells */
}

/* Styles for table headers */
.tableQA th {
    background-color: #f2f2f2; /* Set background color for table headers */
}

/* Styles for update, delete, and add buttons */
.btn-update, .btn-delete, .btn-add  {
    padding: 5px 10px; /* Add padding to buttons */
    background-color: #007bff; /* Set background color for buttons */
    color: #fff; /* Set text color for buttons */
    border: none; /* Remove borders from buttons */
    cursor: pointer; /* Change cursor to pointer on hover */
}

/* Styles for delete buttons */
.btn-delete {
    background-color: #dc3545; /* Set background color for delete buttons */
}

/* Styles for add buttons */
.btn-add {
    background-color: #13602a; /* Set background color for add buttons */
    height: 40px;
}
.update-policy-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}

.update-policy-popup.open {
  display: flex;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.update-policy-popup h2 {
  margin: 0 0 20px;
}

.update-policy-popup label {
  display: block;
  margin: 10px 0;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.update-policy-popup button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.update-policy-popup button:first-child {
  background: #ccc;
}

.update-policy-popup button:last-child {
  background: #007bff;
  color: white;
}
