/* Name:  Varekar, Shraddha */
/* Student Id:  1002071887 */
/* comments:  I have used the font-family Arial, sans-serif and color #f4f4f4, 
    #fff, #e1e1e1, #333, #4c68cc, #007BFF, #000000, in my CSS file. */

.students-pageManage {
    max-width: 600px; /* Setting the max width to 600px */
    margin: 50px auto; /* Setting the margin to 50px auto */
    padding: 40px; /* Adding padding 40px */
    background-color: #fff; /* Setting the background color to white */
    border-radius: 10px; /* Adding border radius 10px */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Adding box shadow */

}
.students-page {
    max-width: 600px; /* Setting the max width to 600px */
    margin: 50px auto; /* Setting the margin to 50px auto */
    padding: 40px; /* Adding padding 40px */
    background-color: #fff; /* Setting the background color to white */
    border-radius: 10px; /* Adding border radius 10px */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Adding box shadow */

}
h1 {
    text-align: center;     /* Center aligning the text */
    margin-bottom: 25px;   /* Adding margin bottom 25px */
    color: #343a40;        /* Setting the color to dark grey */
}

#addStudentBtn {
    display: block; /* Displaying the button as block */
    background-color: #007BFF; /* Setting the background color to blue */
    color: #fff; /* Setting the color to white */
    border: none; 
    padding: 10px 20px; /* Adding padding 10px 20px */
    margin: 20px auto; /* Adding margin 20px auto */
    border-radius: 5px; /* Adding border radius 5px */
    cursor: pointer; /* Setting the cursor to pointer */
    transition: background-color 0.3s; /* Adding transition */
}

#addStudentBtn:hover {
    background-color: #0056b3; /* Changing the background color on hover */
}

.student-list {
    list-style: none; /* Removing the list style */
    margin: 0; /* Removing the margin */
    padding: 0; /* Removing the padding */
}

.student-list li {
    display: flex; /* Displaying the list items as flexbox */
    justify-content: space-between; /* Setting the space between flex items */
    align-items: center; /* Center aligning the flex items vertically */
    background-color: #f8f9fa; /* Setting the background color to light grey */
    padding: 10px; /* Adding padding 10px */
    margin: 10px 0; /* Adding margin 10px 0 */
    border-radius: 5px; /* Adding border radius 5px */
    transition: background-color 0.3s; /* Adding transition */
}

li:hover {
    background-color: #e9ecef;   /* Changing the background color on hover */
}

.student-name {
    cursor: pointer; /* Setting the cursor to pointer */
    color: #007BFF; /* Setting the color to blue */
    text-decoration: none; /* Removing the underline */
    transition: color 0.3s; /* Adding transition */
}

.student-name:hover {
    color: #0056b3; /* Changing the color on hover */
}

.del {
    padding: 5px 15px; /* Adding padding 5px 15px */
    border: none; /* Removing the border */
    border-radius: 5px; /* Adding border radius 5px */
    cursor: pointer; /* Setting the cursor to pointer */
    background-color: #dc3545;      /* Setting the background color to red */
    color: #fff;                  /* Setting the color to white */
    transition: background-color 0.3s; /* Adding transition */
}

.upd{
    background-color: #007BFF; /* Setting the background color to blue */
    color: #fff; /* Setting the color to white */
    transition: background-color 0.3s; /* Adding transition */
}

.upd :hover{
    background-color: #0056b3 !important ; /* Changing the background color on hover */
}

.btns {
    display: flex;  /* Displaying the buttons as flexbox */
    gap: 10px; /* Adding gap between flex items 10px */
}

.students-pageManage form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.students-page form , form input[type="text"], form input[type="password"], form input[type="date"] {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    /* border: 1px solid #e1e1e1; */
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}

.students-pageManage form label, .students-pageManage form input, .students-pageManage form button {
    display: block;
    margin: 10px 0;
    /* if you want to center-align text inside label and input */
    text-align: center;
}

.students-pageManage form label{
    font-weight: bold;
    color:#343a40;
}


.students-page form label, .students-page form input, .students-page form button {
    display: block;
    /* if you want to center-align text inside label and input */
    text-align: center;
    align-items: center;
}
.students-page form {
    display: flex;
    flex-direction: column;
    align-items: center;
}