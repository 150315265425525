/* Name:  Varekar, Shraddha */
/* Student Id:  1002071887 */
/* comments:  I have used the font-family Arial, sans-serif and color #f4f4f4, */
/*     #fff, #e1e1e1, #333, #4c68cc, #007BFF, #000000, in my CSS file. */
/* 
 */



.admin-analytics-container {
    padding: 20px;
    max-width: 90vh;
    margin: 0 auto;
}

.select-report {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 20px;
    width: 100%;
}

.table-responsive {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
}

table th, table td {
    padding: 10px 12px;
    border: 1px solid #ddd;
    text-align: left;
}

table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

/* admin-analytics.css */
.canvas-container {
    width: 100%; /* Allows the container to take the full width */
    max-width: 800px; /* Just an example. Set the max-width as you see fit */
    margin: 0 auto; /* Centers the container */
}


/* Styling for screens smaller than 768px */
@media (max-width: 767px) {
    table td, table th {
        padding: 8px 10px;
        text-align: left;
    }
}

/* For very small screens */
@media (max-width: 480px) {
    table thead {
        display: none;
    }

    table tr {
        margin-bottom: 0.625rem;
        display: block;
        border-bottom: 2px solid #ddd;
    }

    table td {
        display: block;
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    table td::before {
        content: attr(data-label);
        position: absolute;
        left: 10px;
        font-weight: bold;
    }
}
