.modulesContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.modulesContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.modulesContainer .modules-main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 20px;
}

.modulesContainer .modules-nav {
    flex: 1;
    background-color: #f0eded;
    padding: 0;
    transition: flex 0.3s;
}

.modulesContainer .modules-nav a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
}

.modulesContainer .modules-nav a:hover,
.modulesContainer .modules-nav a.modules-selected {
    background-color: #1976d2;
    color: #fff;
}

.modulesContainer .modules-content {
    flex: 3;
    padding: 20px;
    overflow-y: auto;
}

.modulesContainer .add-section-btn,
.modulesContainer .upload-btn {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.modulesContainer .upload-btn {
    margin-right: 10px
}

.modulesContainer .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #1976d2;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.modulesContainer .file-table {
    width: 100%;
    border-collapse: collapse;
}

.modulesContainer .file-table td {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.2s;
}

.modulesContainer .file-table td:hover {
    background-color: #f0f0f0;
}

.modulesContainer .modules-section {
    margin-bottom: 20px;
    /* Added space between sections */
}

/* Add some spacing above the upload button */
.modulesContainer .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #1976d2;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 20px;
    /* Added space above the upload button */
}

/* Styles for the Save Changes button */
.modulesContainer .save-changes-btn {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    /* Center the button and add space on top and bottom */
}


.edit-icon {
    cursor: pointer;
    margin-right: auto;
}

.delete-icon {
    padding-left: 10px;
    cursor: pointer;
}

.section-header-title {
    background-color: transparent;
    font-size: 18px;
    font-weight: bold;
    outline: none;
    border: none;
    display: inline;
    transition: border 0.3s;
    margin-right: 10px;
    /* Add spacing between the input and the icons */
}

.section-header-title:focus {
    border-bottom: 1px solid #1976d2;
}

.modulesContainer .delete-selected-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modulesContainer .delete-selected-btn:hover {
    background-color: darkred;
}

.file-table .file-name {
    cursor: pointer;
}

.file-icons {
    float: right;
    display: flex;
    gap: 10px;
}

.banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    width: auto;
    max-width: 80%;
}

.banner-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.banner-failure {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}