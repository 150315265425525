.announcementsContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.announcementsContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}

.announcementsContainer .announcements-main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.announcementsContainer .announcements-nav {
    flex: 1;
    background-color: #f0eded;
    padding: 0;
    transition: flex 0.3s;
}

.announcementsContainer .announcements-nav a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
}

.announcementsContainer .announcements-nav a:hover,
.announcementsContainer .announcements-nav a.announcements-selected {
    background-color: #1976d2;
    color: #fff;
}

.announcementsContainer .announcements-content {
    flex: 3;
    padding: 20px;
    overflow-y: auto;
    text-align: left;
}

.announcementsContainer .announcements-section {
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 30px;
}

.announcementsContainer .announcements-section h2 {
    border-bottom: 2px solid #1976d2;
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: black;
    text-align: left;
}

.announcementsContainer .announcements-section p {
    color: black;
    text-align: left;
}

.announcementsContainer .create-announcement-btn {
    padding: 10px 15px;
    background-color: #1976d2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-bottom: 20px;
}

.announcementsContainer .create-announcement-btn:hover {
    background-color: #1565c0;
}

@media (max-width: 600px) {
    .announcementsContainer .announcements-nav {
        width: 90%;
    }
}

#quill-editor {
    height: 200px;
    width: 100%;
}

.announcement-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.announcement-modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
}

.announcement-close {
    color: #aaa;
    position: absolute;
    /* Absolute positioning to place it on the top right corner */
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
}

.announcement-close:hover,
.announcement-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.announcement-modal-title {
    font-size: 1.2rem;
}

.announcement-label {
    display: block;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #333;
}

.announcement-input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.announcement-modal-btn {
    display: block;
    background-color: #1976d2;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    align-self: flex-end;
}

.announcement-modal-btn:hover {
    background-color: #1565c0;
}

.banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    width: auto;
    max-width: 80%;
}

.banner-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.banner-failure {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}