/*
    Name: Tomar, Akanksha
    Student Id: 1002039215
*/
/* Style for Header */
.headerContainer {
  background-color: #4c68cc;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.headerLogo {
  font-weight: bold;
  align-self: flex-start;
  padding: 10px 10px 10px 10px;
}
/* Default logo style */
.headerLogo img {
  width: 200px;
  height: auto;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .headerLogo img {
      width: 150px;
      height: auto;
  }
}
.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-self: flex-end;
}

.menu li {
  margin-right: 5px;
}

.menu li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 5px 10px;
}
.footerContainer {
  background-color: #4c68cc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerLine {
  width: 40%;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 20px;
}

.footerSection {
  margin-bottom: 20px;
}

.footerSection ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.footerSection ul li {
  margin-right: 20px;
}

.footerSection ul li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.footerCopy {
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
}

.footerCopy p {
  margin: 0;
  margin-right: 10px;

}
.copyright
{
  color: rgb(255, 255, 255);
}
.hero {background-color: #ffffff;
  /*padding: 80px 20px;*/
  text-align: center;
  }
  .hero h1 {font-size: 36px;
      margin-bottom: 20px;
  }
  .hero p {
      font-size: 18px;
  } 
  .report-card {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
  }
  .report-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .report-description {
    font-size: 16px;
    color: #666;
  }
  .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  p .info{
    font-size: 16px;
    line-height: 1.5;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  th, td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .search-container {
    text-align: center;
    margin-bottom: 20px;
  }
  .search-bar {
    width: 60%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .search-button {
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0a77db;
  }


/* Hide the nested list by default */
.dropdown-contentHeader {
  overflow: visible;
  position: relative;
  display: none;
  position: absolute;
  max-height: 40%;
  /* Set a maximum height for the dropdown */
  overflow-y: auto;
  /* Add vertical scroll if needed */
  background-color: #451dc7;
  min-width: 50px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  list-style-type: none;
  padding: 0%;
  z-index: 1;
}

/* Show the nested list when the parent is hovered or focused */
li:hover .dropdown-contentHeader,
li:focus-within .dropdown-contentHeader {
  display: block;
}

/* Style the nested list */
.dropdown-contentHeader li {
  float: none;
}

.dropdown-contentHeader li a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}



.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
 
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-self: flex-end;
}

.menu li {
  display: block; /* Display normally by default */
}

.menu.open {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 80px; /* Adjust according to your header height */
  left: 0;
  background-color: #4c68cc;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    /* align it to right corner */
    position: absolute;
    top: 12px;
    right: 20px;

  }
  
  .menu li {
    display: none; /* Hide all li elements by default in small screens */
  }
  
  .menu.open {
    display: flex;
  }
  
  .menu.open li {
    display: block; /* Display li elements when menu is open */
  }
}