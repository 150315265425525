/* Name: Sri Harrsha Kolli
   UTAID: 1002063354 */
  
  /* Course Styles */
  .hk .c_container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5; /* Light gray background color */
    border: 1px solid #ccc; /* Grey border */
}
  .hk .content {
    padding: 20px;
  }
  
  /* Course Management Styles */
  .hk .course-card {
    background-color: #fff; /* White background color */
    border: 1px solid #ccc; /* Grey border */
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between; /* Move buttons to the right */
    align-items: center; /* Center vertically */
  }
  .hk .course-name {
    font-size: 20px; /* Increase font size for course names */
    font-weight: bold;
  }
  .hk .course-actions {
    display: flex;
  }
  .hk .course-actions button {
    margin-left: 10px; /* Add space between buttons */
  }
  .hk .create-course-section {
    background-color: #fff; /* White background color */
    border: 1px solid #ccc; /* Grey border */
    padding: 20px;
    margin-bottom: 20px;
  }
  .hk .create-course-section h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .hk .create-course-form {
    display: flex;
    flex-direction: column;
  }
  .hk .create-course-form label {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .hk .create-course-form input[type="text"],
  .hk .create-course-form textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc; /* Grey border */
    margin-bottom: 10px;
    font-size: 16px;
    resize: none;
    margin-left: 0px;
    margin-right: 0px;
  }
  .c_container .create-course-form input[type="text"],
  .c_container .create-course-form textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc; /* Grey border */
    margin-bottom: 10px;
    font-size: 16px;
    resize: none;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  select#course {
    font-size: 16px; /* Adjust the font size as needed */
    padding: 10px;  /* Adjust the padding as needed */
  }
  select#course1 {
    font-size: 16px; /* Adjust the font size as needed */
    padding: 10px;  /* Adjust the padding as needed */
  }
  .hk .create-course-form input[type="submit"] {
    font-size: 18px;
    border: solid;
    border-width: thin;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px 24px;
  }
  
  /* Menu Styles */
 .hk .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
 .hk .menu li {
    margin-right: 10px;
  }
 .hk .menu li a {
    text-decoration: none;
    color: rgb(255, 255, 255); /* White text color */
    padding: 5px 10px;
  }
  

  
  /* Hero Section Styles */
  .hk .hero {
    text-align: center;
  }
  .hk .hero h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: black;
  }
  .hk .hero p {
    font-size: 18px;
  }
  
  /* Report Card Styles */
  .hk .report-card {
    background-color: #fff; /* White background color */
    border: 1px solid #ccc; /* Grey border */
    padding: 20px;
    margin-bottom: 20px;
  }
  .hk .report-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
 .hk .report-description {
    font-size: 16px;
    color: #666; /* Dark gray color */
  }
  
  /* Container Styles */
 .hk .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff; /* White background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
 .hk h1 {
    font-size: 24px;
  }
 .hk h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  /* Paragraph and List Styles */
 .hk p .info {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Table Styles */
 .hk table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
 .hk th, td {
    border: 1px solid #ccc; /* Grey border */
    padding: 10px;
    text-align: left;
  }
 .hk th {
    background-color: #f2f2f2; /* Light gray background color for table header */
    font-weight: bold;
  }
  
  /* Search Styles */
 .hk .search-container {
    text-align: center;
    margin-bottom: 20px;
  }
 .hk .search-bar {
    width: 60%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
 .hk .search-button {
    background-color: #2196f3; /* Blue background color for the search button */
    color: #fff; /* White text color */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
 .hk .search-button:hover {
    background-color: #0a77db; /* Darker blue background color on hover */
  }
  
 