/* Style for Services Page */
.servicesContainer {
    padding: 40px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.servicesContainer .servicesH1 {
    text-align: center;
    color: #333333;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.servicesContainer .servicesP {
    line-height: 1.5;
    color: #666666;
    text-align: left;
    margin-top: 20px;
}

.servicesContainer .servicesSection {
    margin-bottom: 40px;
}

.servicesContainer .servicesSection p:last-child {
    margin-bottom: 0;
}

.servicesContainer .servicesH2 {
    color: #007bff;
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
    padding-left: 40px;
}

/* Style for list items */
.servicesContainer .servicesSection ul {
    padding-left: 40px;
    list-style-type: disc;
}

.servicesContainer .servicesSection li {
    margin-bottom: 5px;
    /* Reduced vertical gap between list items */
    color: #000000;
    /* Black color text */
    font-size: 16px;
}