/*
    Name: Mannam, Raj Kumar
    Student Id: 1002126524
*/

.reportsContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.reportsContainer {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #f7f7f7;
}

.reportsContainer .reports-main-content {
    display: flex;
    flex: 1;
}

.reportsContainer .report-selection {
    margin-bottom: 30px;
    /* Adjusted space after "Select Report:" */
}

.reports-content .metrics {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Manage the gap between items */
}

.reports-content .metric {
    flex: 0 1 calc(33.333% - 20px);
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
}

.reportsContainer .reports-nav {
    flex: 1;
    background-color: #f4f4f4;
    padding: 0;
    transition: flex 0.3s;
}

.reportsContainer .reports-nav a {
    display: block;
    padding: 10px 15px;
    background-color: #f0eded;
    text-decoration: none;
    color: black;
    width: 100%;
    box-sizing: border-box;
}

.reportsContainer .reports-nav a:hover,
.reportsContainer .reports-nav a.reports-selected {
    background-color: #1976d2;
    color: #fff;
}

.reportsContainer .reports-content {
    flex: 3;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.reportsContainer .report-selection label {
    margin-right: 20px;
    font-weight: bold;
}

.reportsContainer .report-selection select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.reportsContainer .metrics {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.reportsContainer .metric {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    flex: 1;
    text-align: center;
}

.reportsContainer .metric-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.reportsContainer .chart-representation img {
    max-width: 100%;
    border: 1px solid #ddd;
}

@media only screen and (max-width: 768px) {

    .reportsContainer .reports-nav,
    .reportsContainer .reports-content {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .reportsContainer .reports-content {
        padding: 5px;
    }
}