/* Name:  Varekar, Shraddha */
/* Student Id:  1002071887 */
/* comments:  I have used the font-family Arial, sans-serif and color #f4f4f4,
    #fff, #e1e1e1, #333, #4c68cc, #007BFF, #000000, in my CSS file. */



    .main-content .dashboard {
        display: flex; /* Displaying dashboard as flexbox */
        padding: 20px; /* Adding padding 20px */
        
    }
    
    .main-content .left-column {
        padding-left: 10%; /* Adding left padding to the left column */
        display: grid; /* Displaying grid */
        gap: 30px; /* Adding gap between grid items 30px */
        grid-template-columns: repeat(4, 1fr); /* Creating 4 columns with equal width */
    }



    
    .main-content .right-column {
        width: 20%; /* Setting the width of the right column to 20% */
        display: flex; /* Displaying right column as flexbox */
        flex-direction: column; /* Setting the direction of flex items to column */
        gap: 20px; /* Adding gap between flex items 20px */
        align-items: center; /* Center align flex items horizontally */
        /* height: 700px; Setting the height of the right column to 700px */
    }
    
    .main-content .grid-item {
        background-color: #fff; /* Setingt background color to white */
        border-radius: 5px; /* Adding border radius 5px */
        padding: 20px; /* Adding padding 20px */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Adding box shadow */
        height: 250px; /* Setting the height of grid items to 250px */
    }
    
    .main-content .content {
        font-size: 24px; /* Setting the font size to 24px */
        text-align: center; /* Center aligning the text */
        margin-bottom: 20px; /* Adding margin bottom 20px */
    }
    
    .main-content .grid-item a {
        display: block; /* Displaying the link as block */
        text-align: center; /* Center aligning the text */
        margin-top: 10px; /* Adding margin top 10px */
        text-decoration: none; /* Removing the underline */
        color: #007BFF; /* Setting the color to blue */
    }
    
    .main-content .grid-item a:hover {
        text-decoration: underline; /* Underlining the link on hover */
    }
    
    
    
    .main-content .activity-feed {
        background-color: #f8f9fa; /* Setting the background color to light grey */
        padding: 15px; /* Adding padding 15px */
        border-radius: 5px; /* Adding border radius 5px */
        margin-bottom: 20px; /* Adding margin bottom 20px */
        max-height: 400px;
        overflow-y: scroll;
    }
    
    .main-content .activity-feed ul {
        list-style-type: none; /* Removing the list style */
        margin: 0; /* Removing the margin */
        padding: 0; /* Removing the padding */
    }
    
    .main-content .activity-feed li {
        padding: 10px 0; /* Adding padding 10px 0 */
        border-bottom: 1px solid #e9ecef; /* Adding border bottom 1px solid light grey */
        font-size: 14px; /* Setting the font size to 14px */
    }
    
    .main-content .activity-feed li:last-child {
        border-bottom: none; /* Removing the border bottom from the last list item */
    }
    
    .main-content .activity-feed span {
        color: #343a40; /* Setting the color to dark grey */
    }
    
    .main-content .timestamp {
        color: #6c757d; /* Setting the color to grey */
        margin-left: 10px; /* Adding margin left 10px */
    }
    
    .main-content h2 {
        font-size: 20px; /* Setting the font size to 20px */
        margin-top: 0; /* Removing the margin top */
    }
    
    .main-content p {
        margin: 0; /* Removing the margin */
    } 

    .main-content .buttons-section {
        display: flex;
        flex-direction: column;
        gap: 10px; /* Spacing between buttons */
        width: 100%;
        margin-bottom: 20px; /* Spacing before the Activity Feed */
    }
    
    .dashboard-button {
        padding: 10px 20px;
        background-color: #007BFF; /* Blue, as used elsewhere */
        color: #fff; /* White text */
        border: none;
        border-radius: 5px; /* Rounded corners */
        cursor: pointer;
        text-align: center;
        width: 90%; /* Takes the full width of the parent */
        font-size: 16px; /* Font size */
        transition: background-color 0.3s; /* Transition for hover effect */
    }
    
    .dashboard-button:hover {
        background-color: #0056b3; /* A darker blue for hover */
    }
    
    
    
    
    
    /* Responsive design */
    @media (max-width: 768px) { /* For screens smaller than 768px */
        .main-content .dashboard { 
            flex-direction: column; /* Stacking the columns vertically */
            padding: 10px; /* Adding padding 10px */
        }
    
        .main-content .left-column, .right-column {
            width: 100%; /* Setting the width of both columns to 100% */
            padding: 0; /* Resetting the padding */
        }
    
        .main-content .left-column {
            grid-template-columns: 1fr; /* Single column layout for mobile */
            gap: 30px; /* Adjust the gap between grid items for mobile */
            padding-left: 0; /* Reset padding */
        }


    
        .main-content .grid-item {
            height: auto; /*content determine the height */
        }
    
        .main-content .content {
            font-size: 18px; /* Reduce font size for mobile */
        }
    
        .main-content .activity-feed li {
            padding: 8px 0; /* Slight reduction in padding for each list item */
        }
    
        .main-content .activity-feed h2, .chat h2 {
            font-size: 18px; /* Adjusted header font size */
        }
    
        .main-content .right-column {
            order: -1; /*To place the right column above the left column on mobile screens*/
            margin-bottom: 20px; /* Some spacing between the two columns when stacked vertically */
            width: 100%;
        }
    }
    