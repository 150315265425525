/*
    Name: Mannam, Raj Kumar
    Student Id: 1002126524
*/

/* General Styles */
.peopleContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.peopleContainer body {
    font-family: Arial, sans-serif;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

/* Main Content Styles */
.peopleContainer .people-main-content {
    display: flex;
    flex: 1;
}

/* Navigation Styles */
.peopleContainer .people-nav {
    flex: 1;
    background-color: #f4f4f4;
    padding: 0;
    transition: flex 0.3s;
}

.peopleContainer .people-nav a {
    display: block;
    padding: 10px 15px;
    background-color: #f0eded;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.peopleContainer .people-nav a:hover,
.peopleContainer .people-nav a.people-selected {
    background-color: #1976d2;
    color: #fff;
}

/* Content Area Styles */
.peopleContainer .people-content {
    flex: 3;
    padding: 20px;
    color: black;
}

/* Person & Icon Styles */
.peopleContainer .person {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.peopleContainer .icon {
    font-size: 30px;
    /* Increasing the size */
    margin-right: 20px;
    /* Adding space */
}