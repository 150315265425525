/*
    Name: Mannam, Raj Kumar
    Student Id: 1002126524
*/

.exams-main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.exams-nav {
    flex: 1;
    background-color: #f0eded;
    padding: 0;
    transition: flex 0.3s;
}

.exams-nav a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.exams-nav a:hover,
.exams-nav a.exams-selected {
    background-color: #1976d2;
    color: #fff;
}

.exams-content {
    flex: 3;
    padding: 20px;
    overflow-y: auto;
    height: 100%;
}

.create-exam {
    margin-bottom: 20px;
    padding: 10px 15px;
    background-color: #1976d2;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
}

.exam {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.exam-icon {
    width: 40px;
    height: 40px;
    background-color: #f0eded;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.exam-details {
    flex: 1;
}

/* Modal Styles */
.exams-main-content .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.exams-main-content .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.exams-main-content .close-btn {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.exams-main-content .close-btn:hover,
.exams-main-content .close-btn:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.exams-main-content .modal label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: 600;
}

.exams-main-content .modal input[type="text"],
.exams-main-content .modal input[type="date"],
.exams-main-content .modal input[type="number"],
.exams-main-content .modal textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
}

.exams-main-content .modal button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 1em;
    transition: background-color 0.3s;
}

.exams-main-content .modal button:hover {
    background-color: #45a049;
}

.banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    width: auto;
    max-width: 80%;
}

.banner-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.banner-failure {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}