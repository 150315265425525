/* Name: Kolli, Sri Harrsha
  UTAID: 1002063354
/*

 /* Contact Page Styles */
 .hk .contact-button {
  background-color: #2196f3; /* Blue background color for contact button */
  color: #fff; /* White text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.hk .contact-button:hover {
  background-color: #0a77db; /* Darker blue background color on hover */
}
.hk .contact-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff; /* White background color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Black shadow */
}
.hk .contact-form label {
  font-weight: bold;
}
.hk .contact-form input[type="text"],
.hk  .contact-form input[type="email"],
.hk .contact-form textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  margin-left: 0px;
  border: 1px solid #ccc; /* Grey border*/
  border-radius: 5px;
  font-size: 16px;
  resize: none;
}
.main-content #forgot-password-form input[type="text"] {
  padding: 10px;  /* Adding padding 10px */
  margin-bottom: 10px; /* Adding margin bottom 10px */
  border: 1px solid #e1e1e1; /* Adding border 1px solid #e1e1e1 */
  margin-left: auto; /* Setting the margin left to auto */
  margin-right: auto; /* Setting the margin right to auto */
  display: block; /* Displaying the input as block */
  width: 300px; /* Setting the width to 300px */
}